import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import FavoritesJson from 'assets/data/favorites.json';

declare var pg: any;
@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.scss']
})
export class FavoriteComponent implements OnInit {
  allCategories = FavoritesJson;

  constructor() { }

  ngOnInit() {

  }
}
