import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import AboutSiteJson from 'assets/data/setups/about-site.json';
import AppsAndToolsJson from 'assets/data/setups/apps-and-tools.json';
import CoffeeJson from 'assets/data/setups/coffee.json';
import DeskJson from 'assets/data/setups/desk.json';
import FavoritesJson from 'assets/data/favorites.json';

declare var pg: any;
@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {
  allCategories: any[] = [];
  permalink: string;

  constructor(private route: ActivatedRoute, private router: Router) 
  { 
    this.router.events.subscribe((_val) => {
      let permalink = this.permalink = this.route.snapshot.paramMap.get('permalink')

      if (permalink === 'website-stack') {
        this.allCategories = AboutSiteJson;
      } else if (permalink === 'apps-and-tools') {
        this.allCategories = AppsAndToolsJson;
      } else if (permalink === 'coffee-notes') {
        this.allCategories = CoffeeJson;    
      } else if (permalink === 'desk-setup') {
        this.allCategories = DeskJson;
      } else if (permalink === 'favorites') {
        this.allCategories = FavoritesJson;
      }
    });
  }

  ngOnInit() {

  }
}
