import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'environments/environment.prod';
import { RootLayout } from '../root/root.component';
import SiteJson from 'assets/data/site.json';
import AboutSiteJson from 'assets/data/setups/about-site.json';

declare var pg: any;
@Component({
  selector: 'executive-layout',
  templateUrl: './executive.component.html',
  styleUrls: ['./executive.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExecutiveLayout extends RootLayout implements OnInit {

  siteJson;
  aboutSiteJson = AboutSiteJson;
  menuItems = [
    {
      label: 'Home',
      routerLink: '/',
      iconType: 'pg',
      iconName: 'home',
      iconClass: 'fa-solid fa-terminal fa-fw'
    },
    // {
    //   label: 'Writings',
    //   routerLink: 'writings',
    //   iconType: 'letter',
    //   iconName: 'fl',
    //   iconClass: 'fa-solid fa-feather fa-fw'
    // },
    // {
    //   label: 'Blog',
    //   iconType: 'pg',
    //   iconName: 'bag',
    //   iconClass: 'fa-solid fa-blog fa-fw',
    //   toggle: 'close',
    //   mToggle: 'close',
    //   submenu: [
    //     {
    //       label: 'Posts',
    //       routerLink: 'blog/posts',
    //       iconType: 'letter',
    //       iconName: 't',
    //       iconClass: 'fa-solid fa-pen fa-fw'
    //     },
    //     {
    //       label: 'Reviews',
    //       routerLink: 'blog/reviews',
    //       iconType: 'letter',
    //       iconName: 't',
    //       iconClass: 'fa-solid fa-magnifying-glass fa-fw'
    //     },
    //     {
    //       label: 'Programming',
    //       routerLink: 'blog/programming',
    //       iconType: 'letter',
    //       iconName: 't',
    //       iconClass: 'fa-solid fa-code fa-fw'
    //     }
    //   ]
    // },
    {
      label: 'Resume',
      routerLink: 'resume',
      iconType: 'letter',
      iconName: 't',
      iconClass: 'fa-solid fa-user-tie fa-fw'
    },
    {
      label: 'Reading',
      routerLink: 'reading',
      iconType: 'letter',
      iconName: 't',
      iconClass: 'fa-solid fa-book-open-reader fa-fw'
    },
    {
      label: 'My',
      iconType: 'pg',
      iconName: 'bag',
      iconClass: 'fa-solid fa-user fa-fw',
      toggle: 'close',
      mToggle: 'close',
      submenu: [
        {
          label: 'Favorites',
          routerLink: 'my/favorites',
          iconType: 'letter',
          iconName: 't',
          iconClass: 'fa-solid fa-star fa-fw'
        },
        {
          label: 'Apps and Tools',
          routerLink: 'my/apps-and-tools',
          iconType: 'letter',
          iconName: 't',
          iconClass: 'fa-solid fa-screwdriver-wrench fa-fw'
        },
        {
          label: 'Desk Setup',
          routerLink: 'my/desk-setup',
          iconType: 'letter',
          iconName: 't',
          iconClass: 'fa-solid fa-computer fa-fw'
        },
        {
          label: 'Coffee Notes',
          routerLink: 'my/coffee-notes',
          iconType: 'letter',
          iconName: 't',
          iconClass: 'fa-solid fa-mug-hot fa-fw'
        },
        {
          label: 'Website Stack',
          routerLink: 'my/website-stack',
          iconType: 'letter',
          iconName: 't',
          iconClass: 'fa-solid fa-cubes-stacked fa-fw'
        }
      ]
    },
  ];

  ngOnInit() {
    pg.isHorizontalLayout = true;
    this.changeLayout('horizontal-menu');
    this.changeLayout('horizontal-app-menu');

    this.siteJson = SiteJson;
  }

  getRss() {
    window.open('rss.xml', '_blank');
  }

  getSitemap() {
    window.open('sitemap.xml', '_blank');
  }

  getFeed() {
    window.open('feed.xml', '_blank');
  }
}
