<pg-container>
  <pg-breadcrumb>
    <ol class="breadcrumb">
      <!-- <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
      <li class="breadcrumb-item active">Cards</li> -->
    </ol>
  </pg-breadcrumb>
  <div class="row">
    <div class="col-lg-4 ">
      <div class="card card-transparent">
        <!-- <div class="card-header">
          <div class="card-title">
            Validation
          </div>
        </div> -->
        <div class="card-body">
          <h3>Contact me.<br></h3>
          <p>You can reach me via the form on the right. I'll try to give a response as soon as possible.</p>

          <hr class="mt-3 mb-3" />

          <a target="_blank" href="mailto:merhaba@oguzhankircali.com"><i class="fa-solid fa-square-envelope fa-fw fa-xl" tooltip="Send an E-Mail"></i></a>
          <a target="_blank" href="https://github.com/oguzhankircali"><i class="fa-brands fa-square-github fa-fw fa-xl" tooltip="GitHub"></i></a>
          <a target="_blank" href="https://www.linkedin.com/in/oguzhankircali"><i class="fa-brands fa-linkedin fa-fw fa-xl" tooltip="LinkedIn"></i></a>
          <a target="_blank" href="https://www.goodreads.com/kircali"><i class="fa-brands fa-goodreads fa-fw fa-xl" tooltip="Goodreads"></i></a>
          <!-- <a target="_blank" href="https://twitter.com/oguzhankircali"><i class="fa-brands fa-twitter fa-fw fa-xl" tooltip="Twitter"></i></a> -->
          <!-- <p class="small hint-text m-t-5">VIA senior product manager<br>
            for UI/UX at REVOX</p> -->


          <!-- <button class="btn btn-primary btn-cons">More</button> -->
        </div>
      </div>
    </div>
    <div class="col-lg-8 ">
      <div class="card card-default">
        <!-- <div class="card-header ">
          <div class="card-title">MODERN LAYOUT.
          </div>
        </div> -->
        <div class="card-body">
          <h2>Let me know.</h2>
          <p class="fs-16 mw-80 m-b-40">Please, do not be hesitated if there is anything that I can help you.
            <!-- <a href="#">Log in</a></p> -->

          <form role="form" #registerForm="ngForm" (ngSubmit)="submitForm()">
            <div class="row">
              <div class="col-md-6">
                <div pgFormGroupDefault class="form-group form-group-default"
                  [class.has-error]="fName.invalid && (fName.dirty || fName.touched)">
                  <label>First Name</label>
                  <input type="text" placeholder="Your first name" class="form-control" id="txtfname" name="txtfname"
                    [(ngModel)]="txtfname" #fName="ngModel" required>
                </div>
                <div *ngIf="fName.invalid && (fName.dirty || fName.touched)">
                  <label *ngIf="fName.errors.required" class="error">This field is required.</label>
                </div>
              </div>
              <div class="col-md-6">
                <div pgFormGroupDefault class="form-group form-group-default"
                  [class.has-error]="LName.invalid && (LName.dirty || LName.touched)">
                  <label>Last Names</label>
                  <input type="text" placeholder="Your last name" class="form-control" id="txtlname" name="txtlname"
                    [(ngModel)]="txtlname" #LName="ngModel" required>
                </div>
                <div *ngIf="LName.invalid && (LName.dirty || LName.touched)">
                  <label *ngIf="LName.errors.required" class="error">This field is required.</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div pgFormGroupDefault class="form-group form-group-default"
                  [class.has-error]="email.invalid && (email.dirty || email.touched)">
                  <label>Email</label>
                  <input type="email" placeholder="Your e-mail address" class="form-control" id="txtemail"
                    name="txtemail" [(ngModel)]="txtemail" #email="ngModel" required>
                </div>
                <div *ngIf="email.invalid && (email.dirty || email.touched)">
                  <label *ngIf="email.errors.required" class="error">This field is required.</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div pgFormGroupDefault class="form-group form-group-default"
                  [class.has-error]="message.invalid && (message.dirty || message.touched)">
                  <label>Message</label>
                  <input type="text" placeholder="Your message" class="form-control"
                    id="txtMessage" name="txtMessage" [(ngModel)]="txtMessage" #message="ngModel" required>
                </div>
                <div *ngIf="message.invalid && (message.dirty || message.touched)">
                  <label *ngIf="message.errors.required" class="error">This field is required.</label>
                </div>
              </div>
            </div>
            <div class="row m-t-10">
              <div class="col-lg-6">
                <!-- <p><small>I agree to the <a href="javascript:void(0)" class="text-info">Pages Terms</a> and <a href="javascript:void(0)" class="text-info">Privacy</a>.</small></p> -->
              </div>
              <div class="col-lg-6 text-right">
                <!-- <a href="javascript:void(0)" class="text-info small">Help? Contact Support</a> -->
              </div>
            </div>
            <button class="btn btn-primary btn-cons m-t-10" type="submit"
              [class.disabled]="registerForm.invalid">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- END BREADCRUMB -->

</pg-container>