import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Blog } from 'app/blog/blog';
import { Post } from 'app/blog/post';
import SiteJson from 'assets/data/site.json';
import { environment } from 'environments/environment.prod';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})

export class PostComponentPage implements OnInit {
  post: Post = new Post();
  siteJson;
  accessToken;

  constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient) {}

  ngOnInit() {
    this.getPostFromApi();
    this.siteJson = SiteJson;
  }

  getPostFromApi() {

    let category = this.route.snapshot.paramMap.get('category');
    let permalink = this.route.snapshot.paramMap.get('permalink');

    let url = environment.apiUrl + '/markdowns/';
    if (category) {
      url += category + '/' + permalink;
    } else {
      url += 'writings/' + permalink
    }

    return this.http.get<Post>(url, {

    }).subscribe(data => {
      this.post = data;
    });
  }
}
