<div class="menu-bar header-sm-height pull-right" #menuWrapper [class.open]="_horizontalMobileMenu" style="text-align: left;">
  <a href="javascript:void(0)" (click)="toggleHorizontalMenu()" class="btn-link header-icon toggle-sidebar d-lg-none">
    <i class="fa-solid fa-xmark fa-lg"></i>
  </a>
  <ul (cdkObserveContent)="_onContentChanges()">
    <li *ngFor="let item of _renduerMenuItems" [class.open]="item.open || item.mToggle ==='open'"
      [class.opening]="item.opening" [class.more]="item.type" [routerLinkActive]="['active']"
      [routerLinkActiveOptions]="{exact: true}">
      <a href="javascript:void(0);" *ngIf="item.submenu;else singleLink" (click)="toggleLink($event,item)">
        <span class="title">
          <ng-template [ngIf]="item.type != 'more'"><i class="{{ item.iconClass }}"></i> {{ item.label }}</ng-template>
          <i *ngIf="item.type" class="pg-icon">more_horizontal</i>
        </span>
        <span *ngIf="item.submenu && !item.type" class=" arrow" [class.open]="item.mToggle ==='open'"></span>
      </a>
      <ng-template #singleLink>
        <a *ngIf="item.routerLink" [routerLink]="[item.routerLink]">
          <span class="title">
            <i class="{{ item.iconClass }}"></i> 
            {{ item.label }}
          </span>
        </a>
        <a *ngIf="item.externalLink" href="{{item.externalLink}}" [attr.target]="item.target"><span
            class="title">{{ item.label }}</span>
        </a>
      </ng-template>
      <ul *ngIf="item.submenu">
        <li *ngFor="let child of item.submenu">
          <a href="javascript:void(0);" *ngIf="child.submenu;else singleLinkChild"><span
              class="title">{{ child.label }}</span>
            <span *ngIf="item.submenu" class=" arrow" [class.open]="item.mToggle ==='open'"></span>
          </a>
          <ng-template #singleLinkChild>
            <a *ngIf="child.routerLink" [routerLink]="[child.routerLink]"><span class="title">
              <i class="{{ child.iconClass }}"></i>
              {{ child.label }}
            </span>
            </a>
            <a *ngIf="child.externalLink" href="{{child.externalLink}}" [attr.target]="child.target"><span
                class="title">{{ child.label }}</span>
            </a>
          </ng-template>
          <ul *ngIf="child.submenu" class="sub-menu">
            <li *ngFor="let lvl2 of child.submenu">
              <a *ngIf="lvl2.routerLink" [routerLink]="[lvl2.routerLink]"><span class="title">{{ lvl2.label }}</span>
              </a>
              <a *ngIf="lvl2.externalLink" [routerLink]="[lvl2.externalLink]" [attr.target]="lvl2.target"><span
                  class="title">{{ item.label }}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <div class="ghost-nav-dropdown" [ngStyle]="item.ghost"></div>
    </li>
    <!-- <li>
      <a href="javascript:void(0)" 
      (click)="openSearch($event)"><i class="fa fa-magnifying-glass fa-fw"></i></a></li> -->
  </ul>
  <ul #menuItemsList class="ghost-ul d-none d-lg-block">
    <li *ngFor="let item of menuItems">
      <a href="javascript:void(0);">
        <span class="title">{{ item.label }}</span>
        <span *ngIf="item.submenu" class=" arrow"></span>
      </a>
    </li>
  </ul>
  <ng-template [ngTemplateOutlet]="mobileSidebarFooter"></ng-template>
</div>
<div class="horizontal-menu-backdrop-dekstop" [style.display]="(currentItem?.open) ? 'block':'none'"
  (click)="closeHorizontalMenu()"></div>
<div class="horizontal-menu-backdrop" [style.display]="_horizontalMobileMenu ? 'block':'none'"
  (click)="toggleHorizontalMenu()"></div>
