import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, OnDestroy, HostBinding, ElementRef, Input, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Blog } from 'app/blog/blog';
import { Post } from 'app/blog/post';
import { environment } from 'environments/environment.prod';
import { Subscription } from 'rxjs';
import { fadeAnimation } from '../../animations/fade-animations';
import { pagesToggleService } from '../../services/toggler.service';
import { SearchResult } from './search-result';
@Component({
  selector: 'app-search-overlay',
  templateUrl: './search-overlay.component.html',
  animations: [fadeAnimation],
  styleUrls: ['./search-overlay.component.scss']
})
export class SearchOverlayComponent implements OnDestroy {
  @ViewChild('searchField') searchField: ElementRef;
  toggleSubscription: Subscription;
  _isEnabled: boolean = false;
  isVisible: boolean = false;
  isSearched: boolean = false;
  value: string = '';
  modal: SearchResult;
  posts: Post[] = [];

  constructor(private el: ElementRef, private toggler: pagesToggleService, private router: Router, private http: HttpClient) {
    this.toggleSubscription = this.toggler.searchToggle.subscribe(toggleValue => {
      this.open();
    });
  }
  ngOnDestroy() {
    this.toggleSubscription.unsubscribe();
  }

  @Input() set isEnabled(isEnabled: boolean) {
    this.isEnabled = isEnabled;
  }
  get isEnabled() {
    return this._isEnabled;
  }

  close($event) {
    $event.preventDefault();
    this.isVisible = false;
    this.value = '';
  }

  open() {
    this.isVisible = true;
    this.value = '';
    setTimeout(() => {
      this.searchField.nativeElement.focus();
    }, 200);
  }

  @HostListener('document:keypress', ['$event']) onKeydownHandler(e) {
    var nodeName = e.target.nodeName;
    // Ignore When focus on input, textarea & contenteditable
    if (nodeName === 'INPUT' || nodeName === 'TEXTAREA' || e.target.contentEditable === 'true') {
      return;
    }
    // Ignore Escape
    if (this.isVisible && e.keyCode === 27) {
      this.isVisible = false;
      this.value = '';
    }
    // Ignore Keyes
    if (e.which !== 0 && e.charCode !== 0 && !e.ctrlKey && !e.metaKey && !e.altKey && e.keyCode !== 27) {
      this.isVisible = true;
      if (!this.value) {
        this.value = String.fromCharCode(e.keyCode | e.charCode);
      }
      this.searchField.nativeElement.focus();
    }
  }

  searchKeyPress(event) {

    // todo: search text can be managed in here.
    console.log(this.value);

    if (this.value.length === 1) {
      setTimeout(() => {
        this.searchField.nativeElement.focus();
      }, 200);
    }

    this.searchPosts(this.value);

    if (this.isVisible && event.keyCode === 27) {
      this.isVisible = false;
      this.value = '';
    }
  }

  fadeInComplete() {
  }

  searchPosts(searchText: string) {

    if (!searchText) {
      this.posts = [];
      return;
    }

    // return this.http.get<Post[]>(environment.apiUrl + '/blog/search/' + searchText).subscribe(data => {
    //   this.posts = data;
    //   this.isSearched = true;
    // });

    this.http.get<Post[]>(environment.apiUrl + '/markdowns/search/' + searchText).subscribe(data => {
      this.posts = data;
      this.isSearched = true;
    });
  }

  redirectToSinglePost(permalink: string): void {
    console.log(permalink);
    this.router.navigate(['blog', permalink]);
  }

  getAllPostsFromApi() {
    return this.http.get<Post[]>(environment.apiUrl + '/blog/posts').subscribe(data => {
      this.posts = data;
    });
  }
}
