import { Routes } from '@angular/router';
//Layouts
import {
  BlankComponent,
  ExecutiveLayout,
  CasualLayout,
  BlankCasualComponent,
  BlankCorporateComponent,
  BlankSimplywhiteComponent,
  DemoComponent
} from '@pages/layouts';

//Sample Pages
import { CondensedDashboardComponent } from './dashboard/condensed/dashboard.component';
// import { SimplyWhiteDashboardComponent } from './dashboard/simplywhite/dashboard.component';
import { CasualDashboardComponent } from './dashboard/casual/dashboard.component';
import { CorporateDashboardComponent } from './dashboard/corporate/dashboard.component';
import { ExecutiveDashboardComponent } from './dashboard/executive/dashboard.component';
import { CardsComponentPage } from './cards/cards.component';
import { ViewsPageComponent } from './views/views.component';
import { ChartsComponent } from './charts/charts.component';
import { SocialComponent } from './social/social.component';
import { PostComponentPage } from './post/post.component';
import { BlogComponentPage } from './blog/blog.component';
import { HomeComponentPage } from './home/home.component';
import { TimelineComponent } from './extra/timeline/timeline.component';
import { ResumeComponent } from './resume/resume.component';
import { ContactComponentPage } from './contact/contact.component';
import { ErrorComponent } from './session/error/error.component';
import { SetupComponent } from './setup/setup.component';
import { ToolboxComponent } from './toolbox/toolbox.component';
import { FormLayoutsComponent } from './forms/form-layouts/form-layouts.component';
import { AboutSiteComponent } from './site/about-site.component';
import { ReadingComponent } from './reading/reading.component';
import { CoffeeComponent } from './coffee/coffee.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: '',
        component: HomeComponentPage,
        data: {
          title: 'home',
          icon: 'fa-terminal'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'blog',
        component: BlogComponentPage,
        data: {
          title: 'blog',
          icon: 'fa-feather fa-fw'
        }
      }
    ]
  },
  //Executive
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'dashboard',
        component: ExecutiveDashboardComponent,
        data: {
          title: 'dashboard'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'blog/:permalink',
        component: PostComponentPage,
        data: {
          title: 'blog/post',
          icon: 'fa-feather'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'resume',
        component: ResumeComponent,
        data: {
          title: 'resume',
          icon: 'fa-user-tie fa-fw'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'setup',
        component: SetupComponent,
        data: {
          title: 'Desk',
          icon: 'fa-house-laptop'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'coffee',
        component: CoffeeComponent,
        data: {
          title: 'Coffee',
          icon: 'fa-mug-hot fa-fw'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'about-site',
        component: AboutSiteComponent,
        data: {
          title: 'Website',
          icon: 'fa-code fa-fw'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'apps-and-tools',
        component: ToolboxComponent,
        data: {
          title: 'App',
          icon: 'fa-toolbox'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'reading',
        component: ReadingComponent,
        data: {
          title: 'reading',
          icon: 'fa-book-open-reader fa-fw'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'contact',
        component: ContactComponentPage,
        data: {
          title: 'contact',
          iconClass: 'fa-solid fa-envelope'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'timeline',
        component: TimelineComponent,
        data: {
          title: 'timeline'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'social',
        component: SocialComponent,
        data: {
          title: 'social'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'extra',
        loadChildren: () => import('./extra/extra.module').then(m => m.ExtraModule)
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'layouts',
        loadChildren: () => import('./layouts/layouts.module').then(m => m.LayoutPageModule)
      }
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'session',
        loadChildren: () => import('./session/session.module').then(m => m.SessionModule)
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'forms',
        loadChildren: () => import('./forms/forms.module').then(m => m.FormsPageModule)
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'ui',
        loadChildren: () => import('./ui/ui.module').then(m => m.UiModule)
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'email',
        loadChildren: () => import('./email/email.module').then(m => m.EmailModule)
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'cards',
        component: CardsComponentPage,
        data: {
          title: 'cards'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'views',
        component: ViewsPageComponent,
        data: {
          title: 'views'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'tables',
        loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule)
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'maps',
        loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule),
        data: {
          title: 'maps'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'charts',
        component: ChartsComponent,
        data: {
          title: 'charts'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'error/:type',
        component: ErrorComponent,
        data: {
          type: '404'
        }
      }
    ]
  },
  { path: '**', redirectTo: 'error/404' }
];
