import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '@pages/components/message/message.service';
import ResumeJson from 'assets/data/resume.json';
import { environment } from 'environments/environment';
declare var pg: any;
@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {
  // Config Docs
  // https://tinesoft.github.io/ng-scrollreveal/doc/injectables/NgsRevealConfig.html

  resumeJson;

  constructor(private router: Router, private http: HttpClient) {

  }

  ngOnInit() {

    this.resumeJson = ResumeJson;
  }

  getResumeGenerationLink() {
    return environment.apiUrl + '/documents/generate-resume';
  }

  generateResumePdf() {
    this.http.get(environment.apiUrl + '/documents/generate-resume', {
      headers: {
        'Content-Type': 'application/pdf'
      }
    }).subscribe(data => {
      alert('Resume.pdf has been generated');
    });
  }

  // createBasicNotification() {
  //   this._notification.create(this.notificationModel.color, this.notificationModel.message, {
  //     Title: 'Oguzhan:',
  //     imgURL: 'assets/img/profiles/avatar_small2x.jpg',
  //     Position: 'top-right',
  //     Style: this.notificationModel.type,
  //     Duration: 0
  //   });
  // }
}
