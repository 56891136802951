import { Routes } from '@angular/router';
//Layouts
import {
  BlankComponent,
  ExecutiveLayout
} from '@pages/layouts';

import { CardsComponentPage } from './cards/cards.component';
import { PostComponentPage } from './post/post.component';
import { BlogComponentPage } from './blog/blog.component';
import { HomeComponentPage } from './home/home.component';
import { TimelineComponent } from './extra/timeline/timeline.component';
import { ResumeComponent } from './resume/resume.component';
import { ContactComponentPage } from './contact/contact.component';
import { ErrorComponent } from './session/error/error.component';
import { SetupComponent } from './setup/setup.component';
import { ReadingComponent } from './reading/reading.component';
import { FavoriteComponent } from './favorite/favorite.component';
import { WritingComponentPage } from './writings/writing.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: '',
        component: HomeComponentPage,
        data: {
          title: 'home',
          icon: 'fa-terminal'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'writings',
        component: WritingComponentPage,
        data: {
          title: 'writings',
          icon: 'fa-feather fa-fw'
        }
      }
    ]
  },
  //Executive
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'writings/:permalink',
        component: PostComponentPage,
        data: {
          title: 'writings',
          icon: 'fa-feather'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'blog/:category',
        component: BlogComponentPage,
        data: {
          title: 'blog',
          icon: 'fa-blog'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'blog/:category/:permalink',
        component: PostComponentPage,
        data: {
          title: 'blog',
          icon: 'fa-blog'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'resume',
        component: ResumeComponent,
        data: {
          title: 'resume',
          icon: 'fa-user-tie fa-fw'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'my/:permalink',
        component: SetupComponent,
        data: {
          title: 'my...',
          // icon: 'fa-user'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'reading',
        component: ReadingComponent,
        data: {
          title: 'reading',
          icon: 'fa-book-open-reader fa-fw'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'contact',
        component: ContactComponentPage,
        data: {
          title: 'contact',
          iconClass: 'fa-solid fa-envelope'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'timeline',
        component: TimelineComponent,
        data: {
          title: 'timeline'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'extra',
        loadChildren: () => import('./extra/extra.module').then(m => m.ExtraModule)
      }
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'session',
        loadChildren: () => import('./session/session.module').then(m => m.SessionModule)
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'ui',
        loadChildren: () => import('./ui/ui.module').then(m => m.UiModule)
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'email',
        loadChildren: () => import('./email/email.module').then(m => m.EmailModule)
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'cards',
        component: CardsComponentPage,
        data: {
          title: 'cards'
        }
      }
    ]
  },
  {
    path: '',
    component: ExecutiveLayout,
    children: [
      {
        path: 'error/:type',
        component: ErrorComponent,
        data: {
          type: '404'
        }
      }
    ]
  },
  { path: '**', redirectTo: 'error/404' }
];
