<pg-container>

  <!-- <div class="row m-lg-0" *ngFor="let post of posts">
    <div class="col-md-6">
      <div>
        <div class="card-body text-center">
          <img class="image-responsive-height m-t-20" src="assets/{{post.imagePath}}" alt="">
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card card-transparent">
        <div class="card-header ">
          <div class="card-title">Getting started
          </div>
        </div>
        <div class="card-body">
          <h3>One of the most underestimated elements of design is typography. However, it’s critical in both
            print and web media. We Made it Perfect in both.</h3>
          <p>This feature in our product specifically creates hinted fonts for various OS browsers; whether
            its Windows, Mac OSx, or ubuntu, it does it for all.</p>
          <br>
          <div>
            <div class="profile-img-wrapper m-t-5 inline">
              <img width="35" height="35" src="assets/img/profiles/avatar_small.jpg" alt="" pgRetina
                src1x="assets/img/profiles/avatar_small.jpg" src2x="assets/img/profiles/avatar_small2x.jpg">
              <div class="chat-status available">
              </div>
            </div>
            <div class="inline m-l-10">
              <p class="small hint-text m-t-5">VIA senior product manage
                <br> for fonts and typography at REVOX
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="row">
    <div class="col-md-2"></div>

    <div class="col-md-8">
      <div class="card card-transparent">
        <div class="card-body">
          <p *ngIf="posts.length == 0">Loading...</p>

          <div class="b-b b-grey m-t-20" *ngFor="let post of posts">
            <!-- <p class="p-b-15">Typography Scale - 1.125</p> -->
            <div class="d-flex justify-content-between m-b-5">
              <!-- <p class="label small-text hint-text">{{post.language | uppercase}}</p> -->
              <p>
                <a class="btn-link" href="javascript:void(0)"
                  (click)="redirectToSinglePost(post.permalink)">{{post.title}}</a>
              </p>

              <p class="hint-text small-text" style="min-width: 110px; text-align: right;">{{ post.publishDate | date:'mediumDate' }}</p>
            </div>
            

            <!-- <p class="m-b-10">{{post.summary}}</p> -->

          </div>
          <!-- <div class="b-b b-grey m-t-20">
            <p class="p-b-15">Typography Scale - 1.125</p>
            <h1 class="m-b-20">The quick brown fox jumps over the lazy dog</h1>
            <div class="d-flex justify-content-between m-b-5">
              <p>h1</p>
              <p>44px/50px Letter-spacing: -0.0141279em</p>
            </div>
          </div>
    
          <div class="b-b b-grey m-t-20">
            <h3 class="m-b-20">Forsaking monastic tradition, twelve jovial friars gave up their
              vocation for a questionable existence on the flying trapeze. Bawds jog, flick quartz,
              vex nymph.</h3>
            <div class="d-flex justify-content-between m-b-5">
              <p>h3</p>
              <p>27px/35px Letter-spacing: -0.0114923em</p>
            </div>
          </div>
    
          <div class="b-b b-grey m-t-20">
            <h4 class="m-b-20">Forsaking monastic tradition, twelve jovial friars gave up their
              vocation for a questionable existence on the flying trapeze. Bawds jog, flick quartz,
              vex nymph.</h4>
            <div class="d-flex justify-content-between m-b-5">
              <p>h4</p>
              <p>22px/32px Letter-spacing: -0.00865734em</p>
            </div>
          </div>
    
          <div class="b-b b-grey m-t-20">
            <h5 class="m-b-20">Forsaking monastic tradition, twelve jovial friars gave up their
              vocation for a questionable existence on the flying trapeze. Bawds jog, flick quartz,
              vex nymph.</h5>
            <div class="d-flex justify-content-between m-b-5">
              <p>h5</p>
              <p>18px/26px Letter-spacing: -0.00630069em</p>
            </div>
          </div>
    
          <div class="b-b b-grey m-t-20">
            <p class="m-b-20">We quickly seized the black axle and just saved it from
              going past him. No kidding, Lorenzo called off his trip to visit Mexico
              City just because they told him the conquistadores were extinct.
              We promptly judged antique onyx buckles for the next prize.</p>
            <div class="d-flex justify-content-between m-b-5">
              <p>paragraph</p>
              <p>44px/52px</p>
            </div>
          </div>
    
          <div class="b-b b-grey m-t-20">
            <p class="overline m-b-20">We quickly seized the black axle and just saved it from
              going past him. No kidding, Lorenzo called off his trip to visit Mexico
              City just because they told him the conquistadores were extinct.
              We promptly judged antique onyx buckles for the next prize.</p>
            <div class="d-flex justify-content-between m-b-5">
              <p>overline</p>
              <p>11px/inherit Letter-spacing: 0.06em</p>
            </div>
          </div>
    
          <div class="b-b b-grey m-t-20 m-b-40">
            <p class="small-text m-b-20">We quickly seized the black axle and just saved it from
              going past him. No kidding, Lorenzo called off his trip to visit Mexico
              City just because they told him the conquistadores were extinct.
              We promptly judged antique onyx buckles for the next prize.</p>
            <div class="d-flex justify-content-between m-b-5">
              <p>small-text</p>
              <p>12px/18px Letter-spacing: 0.00849077em</p>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <div class="col-md-2"></div>
  </div>

</pg-container>