<pg-container>

  <div class="row">
    <div class="col-md-2"></div>

    <div class="col-md-8">

      <div class="card card-transparent">
        <div class="card-body">

          <div class="b-b b-grey m-t-20">
            <div>
              <h1 class="m-b-20">
                <b>{{resumeJson.name}}</b><br>
                <p class="m-t-0">{{resumeJson.title}} <a target="_blank" href="https://thelibertylab.com">The Liberty Lab</a></p> 
              </h1>
            </div>

            <div class="d-flex justify-content-between m-b-5">
              <p class="label small-text"><b>Birth: </b>1988</p>
              <p class="label small-text">English: B2</p>
            </div>

            <p class="b-b b-grey">
              {{resumeJson.summary}}
              <br>
              <!-- <a target="_blank" href="{{getResumeGenerationLink()}}" class="btn btn-primary pull-right"><i class="fa fa-file-pdf"></i>&nbsp; Generate Resume</a><br>
              <span class="small-text hint-text pull-right">click to generate PDF</span>
              <br> -->
              <br>
            </p>

            <h2><b>Skills</b></h2>
            <p *ngFor="let item of resumeJson.skills">
              <b>{{item.label}}</b><br>
              {{item.value}}
            </p>

            <br>
          </div>
          <br>
          <h1><b>Work Experiences</b></h1>
          <div class="b-b b-grey m-t-20" *ngFor="let job of resumeJson.jobs">
            <h2 class="m-b-20">
              <b>{{job.companyName}}</b><br>
              <p class="m-t-0"><b>{{job.title}}</b>
              </p>
            </h2>

            <p class="m-b-20">{{job.description}} <a target="_blank" href="{{job.website}}">{{job.companyShortName}}</a>
            </p>

            <div class="d-flex justify-content-between m-b-5">
              <p class="label small-text">{{ job.startAndEndDate }} <span *ngIf="job.startAndEndDateAsTime">({{
                  job.startAndEndDateAsTime }})</span></p>
              <p class="small-text">{{job.workingType}}, <b title="{{job.country}}">{{job.countryCode}}</b></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-2"></div>
  </div>

</pg-container>