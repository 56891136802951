import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Post } from 'app/blog/post';

@Component({
  selector: 'app-writing',
  templateUrl: './writing.component.html',
  styleUrls: ['./writing.component.scss']
})
export class WritingComponentPage implements OnInit {

  posts: Post[] = [];
  accessToken = '';

  constructor(private router: Router, private http: HttpClient) { }

  ngOnInit() {
    this.getAllPostsFromApi();
  }

  private getToken() {
    this.http.post<any>(environment.apiUrl + '/security/get-token', {
      username: 'oguzhankircali',
      password: 'Qwe.123!api'
    }).subscribe(data => {
      this.accessToken = data.token;

      this.getAllPostsFromApi();
    });
  }

  getAllPostsFromApi() {
    return this.http.get<Post[]>(environment.apiUrl + '/markdowns/writings', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.accessToken
      }
    }).subscribe(data => {
      this.posts = data;
    });
  }

  redirectToSinglePost(permalink: string): void {
    this.router.navigate(['writings', permalink]);
  }
}
