<pg-container>
  <div class="card card-transparent">
    <div class="card-body">

      <div class="row">
        <div class="col-md-8">
          <div class="btn-group d-flex w-100 m-t-20">
            <button aria-label="" type="button"
              class="btn btn-default flex-fill {{filterLabel == 'Currently Reading' ? 'active' : ''}}"
              (click)="filterLabel = 'Currently Reading'">
              <div class="p-t-5 p-b-5">
                <i class="fa-solid fa-book-open-reader fa-fw"></i>
                <div class="fs-11 font-montserrat text-uppercase">
                  Currently Reading
                </div>
              </div>
            </button>
            <button aria-label="" type="button"
              class="btn btn-default flex-fill {{filterLabel == 'Want to Read' ? 'active' : ''}}"
              (click)="filterLabel ='Want to Read'">
              <div class="p-t-5 p-b-5">
                <i class="fa-solid fa-book-bookmark"></i>
                <div class="fs-11 font-montserrat text-uppercase">
                  Want to Read
                </div>
              </div>
            </button>
            <button aria-label="" type="button"
              class="btn btn-default flex-fill {{filterLabel == 'Read' ? 'active' : ''}}"
              (click)="filterLabel = 'Read'">
              <div class="p-t-5 p-b-5">
                <i class="fa-solid fa-book"></i>
                <div class="fs-11 font-montserrat text-uppercase">
                  Read
                </div>
              </div>
            </button>
          </div>

          <div id="gr_custom_widget_1736858014" [hidden]="filterLabel !== 'Currently Reading'"></div>
          <div id="gr_custom_widget_1736928385" [hidden]="filterLabel !== 'Want to Read'"></div>
          <div id="gr_custom_widget_1736858271" [hidden]="filterLabel !== 'Read'"></div>
        </div>
        <div class="col-md-4 m-t-20">
          <div id="gr_challenge_progress_body_11627">
            Loading from Goodreads
          </div>
        </div>
      </div>



    </div>
  </div>
</pg-container>