// Angular Core
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  BrowserModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UntypedFormBuilder, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

// Routing
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

// Layouts
import {
  RootLayout,
  ExecutiveLayout,
  BlankComponent
} from '@pages/layouts';
// Layout Service - Required
import { pagesToggleService } from '@pages/services/toggler.service';

// Shared Layout Components
import { SidebarComponent } from '@pages/components/sidebar/sidebar.component';
import { QuickviewComponent } from '@pages/components/quickview/quickview.component';
import { QuickviewService } from '@pages/components/quickview/quickview.service';
import { SearchOverlayComponent } from '@pages/components/search-overlay/search-overlay.component';
import { HeaderComponent } from '@pages/components/header/header.component';
import { HorizontalMenuComponent } from '@pages/components/horizontal-menu/horizontal-menu.component';
import { SharedModule } from '@pages/components/shared.module';
import { pgListViewModule } from '@pages/components/list-view/list-view.module';
import { pgCardModule } from '@pages/components/card/card.module';
import { pgCardSocialModule } from '@pages/components/card-social/card-social.module';
import { pgCardBookModule } from '@pages/components/card-book/card-book.module';

// Basic Bootstrap Modules
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

// Pages Globaly required Components - Optional
import { pgTabsModule } from '@pages/components/tabs/tabs.module';
import { ProgressModule } from '@pages/components/progress/progress.module';

// Thirdparty Components / Plugins - Optional
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { QuillModule } from 'ngx-quill';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NvD3Module } from 'ng2-nvd3';
import { NgxMasonryModule } from 'ngx-masonry';

// Demo Pages - Optional
import { CardsComponentPage } from './cards/cards.component';

// Sample Blank Pages - Optional
import { BlogComponentPage } from './blog/blog.component';
import { PostComponentPage } from './post/post.component';
import { ContactComponentPage } from './contact/contact.component';
import { BookmarksPageComponent } from './bookmarks/bookmarks.component';
import { HomeComponentPage } from './home/home.component';
import { ResumeComponent } from './resume/resume.component';
import { FirstSentencePipe } from './utils/first-sentence-pipe';
import { ReadingTimePipe } from './utils/reading-time-pipe';
import { SetupComponent } from './setup/setup.component';
import { ReadingComponent } from './reading/reading.component';
import { FavoriteComponent } from './favorite/favorite.component';
import { WritingComponentPage } from './writings/writing.component';
// import { MessageService } from '@pages/components/message/message.service';
// import { UiModule } from './ui/ui.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

// Hammer Config Overide
// https://github.com/angular/angular/issues/10541
@Injectable()
export class AppHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    pinch: { enable: false },
    rotate: { enable: false },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ExecutiveLayout,
    SidebarComponent,
    QuickviewComponent,
    SearchOverlayComponent,
    HeaderComponent,
    HorizontalMenuComponent,
    BlankComponent,
    RootLayout,
    CardsComponentPage,

    BlogComponentPage,
    PostComponentPage,
    WritingComponentPage,
    ContactComponentPage,
    BookmarksPageComponent,
    HomeComponentPage,
    ResumeComponent,
    SetupComponent,
    ReadingComponent,
    FirstSentencePipe,
    ReadingTimePipe,
    FavoriteComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    ProgressModule,
    pgListViewModule,
    pgCardModule,
    pgCardSocialModule,
    pgCardBookModule,
    RouterModule.forRoot(AppRoutes, {}),
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    pgTabsModule,
    //NgxEchartsModule,
    // IsotopeModule,
    NgxDnDModule,
    QuillModule.forRoot(),
    PerfectScrollbarModule,
    NgxMasonryModule,
    //UiModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    NvD3Module,
    CommonModule
  ],

  providers: [
    QuickviewService,
    pagesToggleService,
    UntypedFormBuilder,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: AppHammerConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
