import { Component, ElementRef, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-reading',
  templateUrl: './reading.component.html',
  styleUrls: ['./reading.component.scss']
})
export class ReadingComponent implements OnInit {
  filterLabel = 'Read';
  
  currentlyReadingWidgetScriptUrl = `https://www.goodreads.com/review/custom_widget/62758487.Currently%20Reading?cover_position=left&cover_size=medium&num_books=5&order=d&shelf=currently-reading&show_author=1&show_cover=1&show_rating=0&show_review=0&show_tags=0&show_title=1&sort=date_added&widget_bg_color=FFFFFF&widget_bg_transparent=&widget_border_width=1&widget_id=1736858014&widget_text_color=000000&widget_title_size=medium&widget_width=full`;
  
  wantToReadWidgetScriptUrl = `https://www.goodreads.com/review/custom_widget/62758487.Want%20to%20Read?cover_position=left&cover_size=medium&num_books=15&order=d&shelf=to-read&show_author=1&show_cover=1&show_rating=1&show_review=1&show_tags=0&show_title=1&sort=date_added&widget_bg_color=FFFFFF&widget_bg_transparent=&widget_border_width=1&widget_id=1736928385&widget_text_color=000000&widget_title_size=medium&widget_width=full`;

  readWidgetScriptUrl = `https://www.goodreads.com/review/custom_widget/62758487.Read?cover_position=left&cover_size=medium&num_books=100&order=d&shelf=read&show_author=1&show_cover=1&show_rating=1&show_review=0&show_tags=0&show_title=1&sort=date_read&widget_bg_color=FFFFFF&widget_bg_transparent=&widget_border_width=1&widget_id=1736858271&widget_text_color=000000&widget_title_size=medium&widget_width=full" type="text/javascript" charset="utf-8`;

  readingChallengeWidgetScriptUrl = `https://www.goodreads.com/user_challenges/widget/62758487-o-uzhan-k-r-al?challenge_id=11627&v=2`;


  constructor(private _renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document
  ) 
  { 
    this.appendScript(this.readingChallengeWidgetScriptUrl);
    
    this.appendScript(this.readWidgetScriptUrl);
    this.appendScript(this.currentlyReadingWidgetScriptUrl);
    this.appendScript(this.wantToReadWidgetScriptUrl);
  }

  ngOnInit() {   
    this.filterLabel = 'Currently Reading';
  }

  ngAfterViewInit() {

  }

  private appendScript(scriptUrl) {
    let script = this._renderer2.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.charCode = 'utf-8';
    script.src = scriptUrl;

    this._renderer2.appendChild(this._document.body, script);
  }
}
