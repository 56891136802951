import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Post } from './post';
import { environment } from 'environments/environment';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponentPage implements OnInit {

  posts: Post[] = [];
  accessToken = '';
  category: string;

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient) 
  { 
    this.router.events.subscribe((_val) => {
      var temp = this.route.snapshot.paramMap.get('category');

      if (temp === this.category) {
        return
      }

      this.category = temp;
      this.getAllPostsFromApi();
    });
  }

  ngOnInit() {

  }

  getAllPostsFromApi() {
    return this.http.get<Post[]>(environment.apiUrl + '/markdowns/' + this.category, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.accessToken
      }
    }).subscribe(data => {
      this.posts = data;
    });
  }

  redirectToSinglePost(permalink: string): void {
    this.router.navigate(['blog', this.category, permalink]);
  }
}
