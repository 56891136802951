<pg-container>

  <div class="row">
    <div class="col-md-2"></div>

    <div class="col-md-8">
      
      <div class="card card-transparent">
        <div class="card-body">
          <div *ngFor="let category of allCategories" class="b-b b-grey m-b-20">
            <h4>
              <b>{{category.CategoryName}} <i class="{{category.CategoryIcon}}" style="--fa-animation-iteration-count: 1;"></i> </b>
            </h4>
            <div *ngFor="let item of category.Items">
              <p style="margin-bottom: 0px;">
                {{item.Name}} 
                <a *ngIf="item.WebsiteUrl" target="_blank" href="{{item.WebsiteUrl}}" tooltip="{{item.NameIconTooltip}}"><i class="fa-solid fa-link"></i></a>
                <i *ngIf="!item.WebsiteUrl && item.NameIcon" class="{{item.NameIcon}}" tooltip="{{item.NameIconTooltip}}"></i>
              </p>
              <p class="small-text hint-text">
                {{item.Description}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-2"></div>
  </div>

</pg-container>