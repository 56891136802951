import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import SetupJson from 'assets/data/setup.json';
import { environment } from 'environments/environment.prod';

declare var pg: any;
@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {
  allCategories: any[] = [];

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.getSetups();
  }

  private getSetups() {

    this.http.get<any>(environment.apiUrl + '/setups/desk').subscribe(data => {
      this.allCategories = data;
    });
  }
}
