<pg-container>
  <div class="card card-transparent">
    <div class="card-body">

      <div class="row">
        <div class="col-md-2"></div>

        <div class="col-md-8">
          <!-- <div class="b-b b-grey m-t-20">
            <h1 class="m-b-20">Hello there! 👋</h1>
            <div class="d-flex justify-content-between m-b-5">
              <p>h1</p>
              <p>44px/50px Letter-spacing: -0.0141279em</p>
            </div>
          </div> -->

          <div class="m-t-20">
            <h1 class="m-b-20"><b>Hi, I'm {{siteJson.firstName}}!</b> 👋</h1>
            <p class="m-b-20">{{siteJson.homeText}}
            </p>
            <!-- <p class="m-b-20">
              You can reach my <a [routerLink]="'/writings'">thoughts</a>.
            </p> -->
            <!-- <p class="m-b-20">
              You can see my 
              <a [routerLink]="'/blog/posts'">posts</a>, 
              <a [routerLink]="'/blog/reviews'">reviews</a>,
              <a [routerLink]="'/blog/programming'">programming notes</a> under my blog.
            </p> -->
            <p class="m-b-20">
              You can find my <a [routerLink]="'/resume'">resume</a>.
            </p>
            <p class="m-b-20">
              You can see my <a [routerLink]="'/reading'">readings</a>.
            </p>
            <!-- <p class="m-b-20">
              You can <a [routerLink]="'/contact'">contact</a> with me.
            </p> -->
            <!-- <div class="d-flex justify-content-between m-b-5">
              <p class="small-text">Software Developer</p>
              <p class="small-text">Istanbul, Turkey</p>
            </div> -->
          </div>
        </div>

        <div class="col-md-2"></div>
      </div>
    </div>
  </div>

</pg-container>