import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment.prod';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponentPage implements OnInit {
  accessToken = '';

  txtfname;
  txtlname;
  txtMessage;
  txtpassword;
  txtemail;

  constructor(private http: HttpClient) { }

  ngOnInit() { }

  submitForm() {
    this.sendMessage();
  }

  private getToken() {
    this.http.post<any>(environment.apiUrl + '/security/get-token', {
      username: 'oguzhankircali',
      password: 'Qwe.123!api'
    }).subscribe(data => {
      this.accessToken = data.token;

      console.log(data.token);

      this.sendMessage();
    });
  }

  sendMessage() {
    const headers = { Authorization: 'Bearer ' + this.accessToken };
    const body = { 
      firstName: this.txtfname,
      lastName: this.txtlname,
      email: this.txtemail,
      message: this.txtMessage 
    };
    this.http.post<any>(environment.apiUrl + '/communication/send-telegram-message', body, { headers }).subscribe(data => {

      this.clearForm();
      alert('Message has been sent!');
    });

  }

  clearForm() {
    this.txtfname = '';
    this.txtlname = '';
    this.txtemail = '';
    this.txtMessage = '';
  }
}
